<template>
  <div
    :class="
      cookieConsent === null &&
      hotJarActive === false &&
      currentRoute !== 'error' &&
      currentRoute !== 'cookiepolicy' &&
      activeCoreNode === undefined
        ? 'cookieBannerOffset h-100'
        : 'h-100'
    "
  >
    <DefaultLayout>
      <template #sidebar>
        <MainSidebar
          v-if="coresAvailable && currentRoute !== 'error'"
          v-model:menuSection="menuSection"
          :collapsed="sidebarCollapsed"
          :active-core-node="activeCoreNode"
          :tree="navTree ?? []"
          :home-core-slug="homeCoreSlug"
          :pages="pages"
          :user="user"
          :theme="userSettingsStore.userSettings.theme"
          :language="language"
          :route="route"
          @close="sidebarCollapsed = true"
          @toggle="sidebarCollapsed = !sidebarCollapsed"
          @toggle-theme="toggleTheme"
          @toggle-language="toggleLanguage"
          @toggle-feedback="feedbackOpen = !feedbackOpen"
          @logout="logout"
        />
      </template>

      <template #header>
        <MainHeader
          v-if="currentRoute !== 'error'"
          :feedback-open="feedbackOpen"
          :route="route"
          :sidebar-collapsed="sidebarCollapsed && coresAvailable"
          @update:feedback-open="(value: boolean) => (feedbackOpen = value)"
          @update:sidebar-toggle="onSidebarToggle"
        />
      </template>

      <template #main>
        <InvitationsDialog
          v-if="currentRoute !== 'error'"
          :open="invitationsOpen"
          :invitations="invitations"
          @close="closeInvitationsDialog"
          @open="openInvitationsDialog"
          @accept-invite="acceptInvitation"
          @review-invites="reviewInvites"
        />
        <BannerDialog
          v-if="
            cookieConsent === null &&
            !hotJarActive &&
            currentRoute !== 'error' &&
            currentRoute !== 'cookiepolicy' &&
            coresAvailable
          "
          data-testid="cookie.banner"
        >
          <template #text>
            <div class="container">
              <div class="align-items-center">
                <div>
                  <img
                    id="hideBelowRes"
                    class="img-fluid"
                    src="@/assets/images/logo/logo_dark.png"
                    alt="Ekco Logo"
                    style="max-height: 2rem"
                    data-testid="cookie.banner.brand.logo"
                  />
                </div>
              </div>
            </div>
          </template>
          <template #description>
            <p data-testid="cookie.banner.policy.description">
              {{
                translateText(
                  'We use cookies to enhance your experience. For a complete overview of all cookies used and more info, please see our ',
                )
              }}<router-link
                to="/cookiepolicy"
                style="color: white"
                class="submenu-title"
                router-link-exact-active
                data-testid="cookie.banner.policy.link"
                ><u>{{ translateText('Cookie Policy') }}</u></router-link
              >.
            </p>
          </template>
          <template #buttons>
            <button
              class="btn btn-primary me-2"
              data-testid="cookie.banner.button.accept"
              @click="acceptCookies"
            >
              {{ translateText('Accept') }}
            </button>
            <button
              class="btn btn-danger me-2"
              data-testid="cookie.banner.button.decline"
              @click="declineCookies"
            >
              {{ translateText('Decline') }}
            </button>
          </template>
        </BannerDialog>
        <FeedbackSlideIn v-model="feedbackOpen" />
        <router-view
          v-if="(selectedCustomerAvailable && coresAvailable) || currentRoute === 'error'"
          v-slot="{Component}"
        >
          <transition name="fadeIn" enter-active-class="animated fadeIn">
            <keep-alive>
              <component :is="Component" :key="currentRoute" />
            </keep-alive>
          </transition>
        </router-view>
        <LoadingPage v-else />
      </template>
    </DefaultLayout>
  </div>
</template>
<script lang="ts" setup>
import {LoadingPage} from '.';
import translate from '@/translate';
import {AxiosError} from 'axios';
import config from '@/config';
import {Invitation} from '@/stores/apolloPlatform/team/team';
import {feedbackOpenKey} from '@/components/shared/molecules/FeedbackSlideIn/injectionKeys';

const route = useRoute();
const router = useRouter();

// Composables
const {axiosApiInstance} = useApiInstance();
const {setItem, getItem, removeItem} = useLocalStorage();

// Stores
const commonStore = useCommonStore();
const customersStore = useCustomersStore();
const teamStore = useTeamStore();
const newsStore = useNewsStore();
const coresStore = useCoresStore();
const userStore = useUserStore();
const userSettingsStore = useUserSettingsStore();
const hotJarStore = useHotJarStore();
const widgetStore = useWidgetStore();

const msal = useMsal();

// Store refs
const {hotJarActive} = storeToRefs(hotJarStore);
const {user} = storeToRefs(userStore);

// Refs
const feedbackOpen = ref(false);
const sidebarCollapsed = ref(true);
const invitationsOpen = ref(false);
const cookieConsent = ref<null | boolean>(null);

export type MenuSection = 'menu-settings' | 'menu-default';
const menuSection = ref<MenuSection>('menu-default');

// Computed
const currentRoute = computed(() => route.name);
const invitations = computed(
  () => (user.value && teamStore.userInvitations(user.value?.email)) || [],
);
const language = computed(() => userSettingsStore.userSettings.language);
const selectedCustomerAvailable = computed(
  () => customersStore.selectedCustomer?.customer?.debtor_number !== undefined,
);
const coresAvailable = computed(
  () => coresStore.pages !== null && coresStore.cores !== null && coresStore.homeCoreSlug !== null,
);
const navTree = computed(() => coresStore.navTree);
const homeCoreSlug = computed(() => coresStore.getHomeCoreSlug);
const pages = computed(() => coresStore.getPages);
const activeCoreNode = computed(() => route.meta.coreNode);

watch(language, () => {
  newsStore.refreshNews();
});

const toggleTheme = async (themeItem: string) => {
  userSettingsStore.setUserSettingsTheme(themeItem);
  await userSettingsStore.saveUserSettings();
};

const toggleLanguage = async (language: string) => {
  widgetStore.setLoadedLayout(false);
  userSettingsStore.setUserSettingsLanguage(language);
  await userSettingsStore.saveUserSettings();
  coresStore.switchLanguage();
};

const logout = async () => {
  await msal.instance.logoutRedirect({
    account: msal.instance.getAllAccounts()[0],
    postLogoutRedirectUri: config.REDIRECT_URL,
  });
};

const handleFeedbackOpen = () => {
  feedbackOpen.value = !feedbackOpen.value;
};
provide(feedbackOpenKey, handleFeedbackOpen);

/**
 * Older functions
 */
const throwError = () => {
  router.push({name: 'error'});
};
const translateText = (input: string) => {
  return translate.translate(language.value, input);
};
const openInvitationsDialog = () => (invitationsOpen.value = true);
const closeInvitationsDialog = async () => {
  invitationsOpen.value = false;
  if (customersStore.customers?.length === 0) {
    console.error('No customers');
    throwError();
  }
};
const acceptCookies = () => {
  try {
    setItem('cookieConsent', true);
    hotJarStore.startHotJar();
    if (currentRoute.value === 'cookiepolicy') {
      router.push({name: 'home'});
    }
  } catch (err) {
    console.log(err);
  }
};
const declineCookies = () => {
  setItem('cookieConsent', false);
  cookieConsent.value = getItem('cookieConsent');
};
const reviewInvites = () => router.push('team');
const acceptInvitation = async (invitation: Invitation) => {
  //for each customer in invitation add name string to this.customers
  const customers: Customer[] = (customersStore.customers as Customer[]) || [];
  invitation.customers.forEach((customer: any) => customers.push(customer));
  customersStore.setCustomers(customers);
  if (invitation.customers.length > 0) {
    customersStore.readCustomerById(invitation.customers[0].id);
  }
  const config = {
    method: 'put',
    url: ['invitations/', invitation.id, '/accept/'].join(''),
  };
  await axiosApiInstance(config).then(async () => {
    location.reload();
  });
};

const checkForCustomers = () => {
  if (
    invitations.value.length === 0 &&
    (!customersStore.customers || customersStore.customers.length === 0) &&
    currentRoute.value !== 'error' &&
    user.value?.is_staff === false &&
    customersStore.selectedCustomer === null
  ) {
    console.error('No customer or invitations');
    commonStore.setError('customerInvitation');
    throwError();
  }
  if (
    invitations.value.length >= 1 &&
    currentRoute.value !== 'team' &&
    currentRoute.value !== 'Page'
  ) {
    openInvitationsDialog();
  }
};

/**
 * Initialize the selected customer, either from local storage, or the
 * existing customers list
 */
const initSelectedCustomer = async () => {
  const customers: Customer[] = customersStore.customers || [];

  if (customers.length === 0) {
    // Dealing with a new user, skip the selected customer initialization
    return;
  }

  let selectedCustomer: SelectedCustomer | null = getItem('selectedCustomer');

  if (!selectedCustomer) {
    selectedCustomer = await customersStore.readCustomerById(customers[0].id);
  }

  /**
   * Whem new session selectedCustomer might be missing from first pagination.
   * Need to merge selectedCustomer into customers before setting.
   */
  const duplicateCustomers: Customer[] = [selectedCustomer.customer, ...customers];
  // https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
  const uniqueCustomers: Customer[] = duplicateCustomers.filter(
    (e: Customer, i: number, a: Customer[]) =>
      i === a.findIndex((t: Customer) => t.id === e.id && t.name === e.name), // element, index, array
  );

  // Set the new de-duplicated customers
  customersStore.setCustomers(uniqueCustomers);
  customersStore.setSelectedCustomer(selectedCustomer);
  /**
   * When switching between envs & older user local storage,
   * customers/selectedCustomer ids may not be in sync with those in the db.
   * i.e Bovag with id 1 on local, Bovan with id 2 on dev-1, etc.
   */
  try {
    await customersStore.readCustomerById(selectedCustomer.customer.id);
  } catch (errorMessage) {
    if (errorMessage === 'Not Found') {
      removeItem('selectedCustomer');
      throwError();
    }
  }
};

onBeforeMount(async () => {
  // The order of these calls is significant.
  //
  // Any endpoint which has user management filtering enabled expects a
  // "Sphere-Customer-ID" header to be present. This header is added to the
  // request automatically, but it requires the selected customer to be
  // available first.
  //
  // Currently, the user permissions, navigation, documents and folders endpoints
  // expect this header. If it's ever necessary to reorder these calls, care
  // should be taken to ensure the selected customer is available before
  // calling these endpoints.
  //
  // Additionally, the user must be available before accessing anything in
  // local storage.

  try {
    await userStore.readUser();
  } catch (error) {
    // This is one of the first authenticated requests the portal does, so this
    // is where many authentication/account issues will first surface.
    try {
      if (error instanceof AxiosError) {
        commonStore.setError(error.response?.data.detail);
      }
    } catch {
      console.error("Couldn't set sphere error type");
    }

    throwError();
  }

  cookieConsent.value = getItem('cookieConsent');

  // Retrieve and check customers and invitations at the outset.
  // This allows us to handle new users correctly.
  await Promise.all([customersStore.readCustomers(), teamStore.fetchInvitations()]);
  checkForCustomers();
  await initSelectedCustomer();

  // From here we know what customers/invitations the user has and we can
  // assume the selected customer has already been set
  await Promise.all([coresStore.readPortalNavigation(), userStore.readUserPermissions()]);

  if (getItem('cookieConsent') === true) {
    hotJarStore.startHotJar();
  }

  if (currentRoute.value === 'team' && !selectedCustomerAvailable.value) {
    router.push({name: 'home'});
  }
});

watch(
  () => activeCoreNode.value?.core.nav_type,
  () => {
    if (
      (activeCoreNode.value?.core.nav_type === 'dashboard' || currentRoute.value === 'team') &&
      !sidebarCollapsed.value
    ) {
      sidebarCollapsed.value = true;
    }
  },
  {immediate: true},
);

const onSidebarToggle = (value: string) => {
  if (value === 'menu-settings') {
    menuSection.value = 'menu-settings';
    if (sidebarCollapsed.value) sidebarCollapsed.value = false;
  } else {
    menuSection.value = 'menu-default';
    sidebarCollapsed.value = !sidebarCollapsed.value;
  }
};
</script>
<style lang="scss" scoped>
.pageBodySidebarHidden {
  margin-left: unset !important;
  width: 100% !important;
}

.sidebarHide {
  display: none;
}

.cookieBannerOffset {
  padding-bottom: 100px;
}
</style>
